export default {
  agreementsSchema: [
    {
      label: 'offers and announcements',
      description: '',
      agreements: [
        {
          label: 'Email',
          description: 'loyalty mail agreement description',
          channel: 'EMAIL',
          topic: 'ESHOP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: false,
          },
        },
        {
          label: 'SMS',
          description: 'loyalty sms agreement description',
          channel: 'SMS',
          topic: 'ESHOP',
          hasPhoneNumber: true,
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: false,
          },
        },
      ],
    },
    {
      label: 'offers and announcements',
      description: '',
      agreements: [
        {
          label: 'Email',
          description: 'loyalty mail agreement description',
          channel: 'EMAIL',
          topic: 'ESHOP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
        {
          label: 'SMS',
          description: 'loyalty sms agreement description',
          channel: 'SMS',
          topic: 'ESHOP',
          hasPhoneNumber: true,
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
      ],
    },
    {
      label: 'Drmax offers and loyalty program announcements',
      description: '',
      agreements: [
        {
          label: 'Email',
          description: 'loyalty mail agreement description',
          channel: 'EMAIL',
          topic: 'CP',
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
        {
          label: 'SMS',
          description: 'loyalty sms agreement description',
          channel: 'SMS',
          topic: 'CP',
          hasPhoneNumber: true,
          sorpAvailabilityOptions: {
            activeAccount: true,
            fullAccount: true,
          },
        },
      ],
    },
  ]
}
