export default {
  footer: {
    links: [
      {
        url: '/termeni-conditii',
        text: 'Termeni si conditii',
      },
      {
        url: '/politica-confidentialitate',
        text: 'Politica de confidentialitate',
      },
      {
        url: '/cum-se-livreaza',
        text: 'Cum se livreaza',
      },
      {
        url: '/cum-platesc',
        text: 'Cum platesc',
      },
      {
        url: '/cookies',
        text: 'Cookies',
      },
      {
        url: '/ridicare-gratuita-in-farmacie',
        text: 'Ridicare gratuita din farmacii',
      },
      {
        url: '/politica-de-retur',
        text: 'Politica de retur',
      },
    ],
    legalText: 'DR.MAX S.R.L., CUI: 9378655, J23/673/2007, sediu Mogoșoaia, str. Aeroportului, nr. 53, Ilfov, punct de lucru: Mogoșoaia, str. Buiacului, nr. 2, Zona de Depozitare Suplimentară și Zona de Mezanin Suplimentară, Ilfov, autorizația MS <a href="https://backend.drmax.ro/media/wysiwyg/buiacului-drogherie.pdf" target="_blank">3059/NT3480/04.06.2020</a>',
  },
}
