import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ed10c186 = () => interopDefault(import('../app/common/modules/articles/pages/authors/index.vue' /* webpackChunkName: "pages/authors/index" */))
const _3aef5743 = () => interopDefault(import('../app/common/modules/brands/pages/brand-preview.vue' /* webpackChunkName: "pages/brand-preview" */))
const _cac50144 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _0f680562 = () => interopDefault(import('../app/common/modules/cms/pages/cms-entity-preview.vue' /* webpackChunkName: "pages/cms-entity-preview" */))
const _5c42c0aa = () => interopDefault(import('../layers/multi-app/layers/ro-drmax/app/pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _19be287e = () => interopDefault(import('../app/common/modules/tags/pages/tags/index.vue' /* webpackChunkName: "pages/tags/index" */))
const _49b7a016 = () => interopDefault(import('../app/common/modules/faq/pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _b30644fe = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/index.vue' /* webpackChunkName: "pages/pharmacies/index" */))
const _758cfae0 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/index.vue' /* webpackChunkName: "pages/askPharmacist/index" */))
const _926d8ac4 = () => interopDefault(import('../app/common/modules/brands/pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _d73fc74a = () => interopDefault(import('../app/common/modules/my-account/pages/my-account.vue' /* webpackChunkName: "pages/my-account" */))
const _2c8e215c = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/baby-club/index.vue' /* webpackChunkName: "pages/my-account/baby-club/index" */))
const _47029ac4 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/index.vue' /* webpackChunkName: "pages/my-account/index" */))
const _29fe43b2 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/my-alerts/index.vue' /* webpackChunkName: "pages/my-account/my-alerts/index" */))
const _4e3714f5 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/my-orders/index.vue' /* webpackChunkName: "pages/my-account/my-orders/index" */))
const _524d1a22 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/my-profile/index.vue' /* webpackChunkName: "pages/my-account/my-profile/index" */))
const _29f1235d = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/my-favorites/index.vue' /* webpackChunkName: "pages/my-account/my-favorites/index" */))
const _48aca5b2 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/my-offers/index.vue' /* webpackChunkName: "pages/my-account/my-offers/index" */))
const _382edd67 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/index.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/index" */))
const _f43f25fc = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/return/_id/index.vue' /* webpackChunkName: "pages/my-account/return/_id/index" */))
const _10761d90 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/new-return.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/new-return" */))
const _1327d364 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/new-return/step1.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/new-return/step1" */))
const _130ba462 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/new-return/step2.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/new-return/step2" */))
const _12ef7560 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/new-return/step3.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/new-return/step3" */))
const _5fee15ed = () => interopDefault(import('../app/common/modules/my-account/pages/my-account/purchase/_orderId/new-return/step3-logistic.vue' /* webpackChunkName: "pages/my-account/purchase/_orderId/new-return/step3-logistic" */))
const _5dabcd6a = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public.vue' /* webpackChunkName: "pages/my-account-public" */))
const _55b3630a = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/amazon/index.vue' /* webpackChunkName: "pages/my-account-public/amazon/index" */))
const _1f94193e = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/competition/index.vue' /* webpackChunkName: "pages/my-account-public/competition/index" */))
const _00037292 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/email-communication-unsubscription.vue' /* webpackChunkName: "pages/my-account-public/email-communication-unsubscription" */))
const _db240dc4 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/favorite-pharmacies.vue' /* webpackChunkName: "pages/my-account-public/favorite-pharmacies" */))
const _13dc0304 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/logout.vue' /* webpackChunkName: "pages/my-account-public/logout" */))
const _3208a5a6 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/registration.vue' /* webpackChunkName: "pages/my-account-public/registration" */))
const _1a7d72fa = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/registration/step1.vue' /* webpackChunkName: "pages/my-account-public/registration/step1" */))
const _1a6143f8 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/registration/step2.vue' /* webpackChunkName: "pages/my-account-public/registration/step2" */))
const _1a4514f6 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/registration/step3.vue' /* webpackChunkName: "pages/my-account-public/registration/step3" */))
const _1a28e5f4 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/registration/step4.vue' /* webpackChunkName: "pages/my-account-public/registration/step4" */))
const _2706bb8a = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/set-token.vue' /* webpackChunkName: "pages/my-account-public/set-token" */))
const _54e00093 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/favorites.vue' /* webpackChunkName: "pages/my-account-public/favorites" */))
const _3131e5b9 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/competition/thankyou.vue' /* webpackChunkName: "pages/my-account-public/competition/thankyou" */))
const _3facf496 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/choose-card-type.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/choose-card-type" */))
const _2a674d28 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/first-login.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/first-login" */))
const _4b609db0 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/unsubscription-confirmation.vue' /* webpackChunkName: "pages/my-account-public/unsubscription-confirmation" */))
const _fabeeaf6 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step5.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step5" */))
const _fb2fa6fe = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step1.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step1" */))
const _fb1377fc = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step2.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step2" */))
const _8fe5d966 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step3-personal-data.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step3-personal-data" */))
const _1e2c9f6a = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step3-pin.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step3-pin" */))
const _fadb19f8 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public/loyalty-card/step4.vue' /* webpackChunkName: "pages/my-account-public/loyalty-card/step4" */))
const _d548c0e8 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public-sorp.vue' /* webpackChunkName: "pages/my-account-public-sorp" */))
const _a977cee8 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public-sorp/registration.vue' /* webpackChunkName: "pages/my-account-public-sorp/registration" */))
const _06192f6f = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public-sorp/final-step-loginfailed.vue' /* webpackChunkName: "pages/my-account-public-sorp/final-step-loginfailed" */))
const _5c262549 = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public-sorp/final-step-not-verified.vue' /* webpackChunkName: "pages/my-account-public-sorp/final-step-not-verified" */))
const _1edb2c0f = () => interopDefault(import('../app/common/modules/my-account/pages/my-account-public-sorp/final-step-verified.vue' /* webpackChunkName: "pages/my-account-public-sorp/final-step-verified" */))
const _8315cbf8 = () => interopDefault(import('../app/common/modules/subscription/pages/newsletter-subscriber-confirm/index.vue' /* webpackChunkName: "pages/newsletter-subscriber-confirm/index" */))
const _3ca5c49a = () => interopDefault(import('../layers/special-offers/app/pages/special-offers.vue' /* webpackChunkName: "pages/special-offers" */))
const _18439b52 = () => interopDefault(import('../app/common/modules/cms/pages/pagebuilder-preview.vue' /* webpackChunkName: "pages/pagebuilder-preview" */))
const _30883173 = () => interopDefault(import('../app/common/modules/article-preview/pages/post-preview.vue' /* webpackChunkName: "pages/post-preview" */))
const _27f0e77b = () => interopDefault(import('../app/common/modules/product-preview/pages/product-preview.vue' /* webpackChunkName: "pages/product-preview" */))
const _04d188b4 = () => interopDefault(import('../app/common/modules/loyalty-page/pages/loyalty/index.vue' /* webpackChunkName: "pages/loyalty/index" */))
const _55a216cc = () => interopDefault(import('../app/common/modules/my-account/pages/new-return.vue' /* webpackChunkName: "pages/new-return" */))
const _d398863c = () => interopDefault(import('../app/common/modules/my-account/pages/new-return/step1.vue' /* webpackChunkName: "pages/new-return/step1" */))
const _d37c573a = () => interopDefault(import('../app/common/modules/my-account/pages/new-return/step2.vue' /* webpackChunkName: "pages/new-return/step2" */))
const _d3602838 = () => interopDefault(import('../app/common/modules/my-account/pages/new-return/step3.vue' /* webpackChunkName: "pages/new-return/step3" */))
const _69d695d9 = () => interopDefault(import('../app/common/modules/my-account/pages/new-return/step3-logistic.vue' /* webpackChunkName: "pages/new-return/step3-logistic" */))
const _a3216ef2 = () => interopDefault(import('../app/common/modules/my-account/pages/returns/index.vue' /* webpackChunkName: "pages/returns/index" */))
const _3d979cd4 = () => interopDefault(import('../app/common/modules/clubs/pages/baby/medicament.vue' /* webpackChunkName: "pages/baby/medicament" */))
const _6d83b008 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/payment-failed.vue' /* webpackChunkName: "pages/checkout/payment-failed" */))
const _1e8c0b67 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/payment-redirect.vue' /* webpackChunkName: "pages/checkout/payment-redirect" */))
const _1bf54b99 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/thankyou.vue' /* webpackChunkName: "pages/checkout/thankyou" */))
const _11bbfa0a = () => interopDefault(import('../app/common/modules/my-account/pages/email-verification.vue' /* webpackChunkName: "pages/email-verification" */))
const _0d66fd2b = () => interopDefault(import('../app/common/modules/my-account/pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _24028d05 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/callCenter.vue' /* webpackChunkName: "pages/askPharmacist/callCenter" */))
const _510c7e43 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/pharmacies.vue' /* webpackChunkName: "pages/askPharmacist/pharmacies" */))
const _68ec607c = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/questionForm.vue' /* webpackChunkName: "pages/askPharmacist/questionForm" */))
const _c634f154 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/questionList.vue' /* webpackChunkName: "pages/askPharmacist/questionList" */))
const _3ef9471f = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/dermocenters/our-dermocenters.vue' /* webpackChunkName: "pages/pharmacies/dermocenters/our-dermocenters" */))
const _74224362 = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/dermocenters/social-networks.vue' /* webpackChunkName: "pages/pharmacies/dermocenters/social-networks" */))
const _13eeb22d = () => interopDefault(import('../layers/multi-app/layers/ro-drmax/app/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9bbc16d0 = () => interopDefault(import('../app/common/modules/my-account/pages/confirmation/_token/index.vue' /* webpackChunkName: "pages/confirmation/_token/index" */))
const _59f42558 = () => interopDefault(import('../app/common/modules/my-account/pages/new-password/_hash/index.vue' /* webpackChunkName: "pages/new-password/_hash/index" */))
const _0991542e = () => interopDefault(import('../app/common/modules/my-account/pages/confirmation-email/_token/index.vue' /* webpackChunkName: "pages/confirmation-email/_token/index" */))
const _42d40a9f = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/dermocenters/_dermocenter/index.vue' /* webpackChunkName: "pages/pharmacies/dermocenters/_dermocenter/index" */))
const _3f61ff56 = () => interopDefault(import('../app/common/modules/articles/pages/authors/_author.vue' /* webpackChunkName: "pages/authors/_author" */))
const _254a01b7 = () => interopDefault(import('../app/common/modules/checkout/pages/checkout/_step.vue' /* webpackChunkName: "pages/checkout/_step" */))
const _5092b942 = () => interopDefault(import('../app/common/modules/tags/pages/tags/_tag.vue' /* webpackChunkName: "pages/tags/_tag" */))
const _18407904 = () => interopDefault(import('../app/common/modules/apotheka-web/pages/pharmacies/_pharmacy/index.vue' /* webpackChunkName: "pages/pharmacies/_pharmacy/index" */))
const _229fd283 = () => interopDefault(import('../app/common/modules/cms/pages/askPharmacist/_question.vue' /* webpackChunkName: "pages/askPharmacist/_question" */))
const _040cbcae = () => interopDefault(import('../app/common/modules/checkout/pages/repay/_hash.vue' /* webpackChunkName: "pages/repay/_hash" */))
const _7fe3d023 = () => interopDefault(import('../app/common/modules/catalog/pages/promotion.vue' /* webpackChunkName: "pages/promotion" */))
const _be9fffc4 = () => interopDefault(import('../app/common/modules/search/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _242aae65 = () => interopDefault(import('../app/common/modules/base/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'isCurrent',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/autori",
    component: _ed10c186,
    name: "authors___ro-drmax"
  }, {
    path: "/brand-preview",
    component: _3aef5743,
    name: "brand-preview___ro-drmax"
  }, {
    path: "/checkout",
    component: _cac50144,
    name: "checkout___ro-drmax"
  }, {
    path: "/cms-entity-preview",
    component: _0f680562,
    name: "cms-entity-preview___ro-drmax"
  }, {
    path: "/cookies",
    component: _5c42c0aa,
    name: "cookies___ro-drmax",
    children: []
  }, {
    path: "/etichete",
    component: _19be287e,
    name: "tags___ro-drmax"
  }, {
    path: "/faq",
    component: _49b7a016,
    name: "faq___ro-drmax"
  }, {
    path: "/farmacii",
    component: _b30644fe,
    name: "pharmacies___ro-drmax"
  }, {
    path: "/intreaba-farmacistul",
    component: _758cfae0,
    name: "askPharmacist___ro-drmax"
  }, {
    path: "/marci",
    component: _926d8ac4,
    name: "brands___ro-drmax"
  }, {
    path: "/my-account",
    component: _d73fc74a,
    children: [{
      path: "baby-club",
      component: _2c8e215c,
      name: "my-account-baby-club___ro-drmax"
    }, {
      path: "/contul-meu",
      component: _47029ac4,
      name: "my-account___ro-drmax"
    }, {
      path: "/contul-meu/alerte",
      component: _29fe43b2,
      name: "my-account-my-alerts___ro-drmax"
    }, {
      path: "/contul-meu/comenzi",
      component: _4e3714f5,
      name: "my-account-my-orders___ro-drmax"
    }, {
      path: "/contul-meu/editeaza",
      component: _524d1a22,
      name: "my-account-my-profile___ro-drmax"
    }, {
      path: "/contul-meu/lista-favorite",
      component: _29f1235d,
      name: "my-account-my-favorites___ro-drmax"
    }, {
      path: "/contul-meu/oferte",
      component: _48aca5b2,
      name: "my-account-my-offers___ro-drmax"
    }, {
      path: "/contul-meu/comanda/:orderId",
      component: _382edd67,
      name: "my-account-purchase-orderId___ro-drmax"
    }, {
      path: "/contul-meu/reclamatie/:id",
      component: _f43f25fc,
      name: "my-account-return-id___ro-drmax"
    }, {
      path: "/contul-meu/comanda/:orderId/reclamatie",
      component: _10761d90,
      name: "my-account-purchase-orderId-new-return___ro-drmax",
      children: [{
        path: "/contul-meu/comanda/:orderId/reclamatie/1",
        component: _1327d364,
        name: "my-account-purchase-orderId-new-return-step1___ro-drmax"
      }, {
        path: "/contul-meu/comanda/:orderId/reclamatie/2",
        component: _130ba462,
        name: "my-account-purchase-orderId-new-return-step2___ro-drmax"
      }, {
        path: "/contul-meu/comanda/:orderId/reclamatie/3",
        component: _12ef7560,
        name: "my-account-purchase-orderId-new-return-step3___ro-drmax"
      }, {
        path: "/contul-meu/comanda/:orderId/reclamatie/3-logistic",
        component: _5fee15ed,
        name: "my-account-purchase-orderId-new-return-step3-logistic___ro-drmax"
      }]
    }]
  }, {
    path: "/my-account",
    component: _d73fc74a,
    children: [{
      path: "baby-club",
      component: _2c8e215c,
      name: "my-account-baby-club___ro-drmax"
    }, {
      path: "/contul-meu",
      component: _47029ac4,
      name: "my-account___ro-drmax"
    }, {
      path: "/contul-meu/alerte",
      component: _29fe43b2,
      name: "my-account-my-alerts___ro-drmax"
    }, {
      path: "/contul-meu/comenzi",
      component: _4e3714f5,
      name: "my-account-my-orders___ro-drmax"
    }, {
      path: "/contul-meu/editeaza",
      component: _524d1a22,
      name: "my-account-my-profile___ro-drmax"
    }, {
      path: "/contul-meu/lista-favorite",
      component: _29f1235d,
      name: "my-account-my-favorites___ro-drmax"
    }, {
      path: "/contul-meu/oferte",
      component: _48aca5b2,
      name: "my-account-my-offers___ro-drmax"
    }, {
      path: "/contul-meu/comanda/:orderId",
      component: _382edd67,
      name: "my-account-purchase-orderId___ro-drmax"
    }, {
      path: "/contul-meu/reclamatie/:id",
      component: _f43f25fc,
      name: "my-account-return-id___ro-drmax"
    }, {
      path: "/contul-meu/comanda/:orderId/reclamatie",
      component: _10761d90,
      name: "my-account-purchase-orderId-new-return___ro-drmax",
      children: [{
        path: "/contul-meu/comanda/:orderId/reclamatie/1",
        component: _1327d364,
        name: "my-account-purchase-orderId-new-return-step1___ro-drmax"
      }, {
        path: "/contul-meu/comanda/:orderId/reclamatie/2",
        component: _130ba462,
        name: "my-account-purchase-orderId-new-return-step2___ro-drmax"
      }, {
        path: "/contul-meu/comanda/:orderId/reclamatie/3",
        component: _12ef7560,
        name: "my-account-purchase-orderId-new-return-step3___ro-drmax"
      }, {
        path: "/contul-meu/comanda/:orderId/reclamatie/3-logistic",
        component: _5fee15ed,
        name: "my-account-purchase-orderId-new-return-step3-logistic___ro-drmax"
      }]
    }]
  }, {
    path: "/my-account-public",
    component: _5dabcd6a,
    name: "my-account-public___ro-drmax",
    children: [{
      path: "amazon",
      component: _55b3630a,
      name: "my-account-public-amazon___ro-drmax"
    }, {
      path: "competition",
      component: _1f94193e,
      name: "my-account-public-competition___ro-drmax"
    }, {
      path: "email-communication-unsubscription",
      component: _00037292,
      name: "my-account-public-email-communication-unsubscription___ro-drmax"
    }, {
      path: "favorite-pharmacies",
      component: _db240dc4,
      name: "my-account-public-favorite-pharmacies___ro-drmax"
    }, {
      path: "logout",
      component: _13dc0304,
      name: "my-account-public-logout___ro-drmax"
    }, {
      path: "registration",
      component: _3208a5a6,
      name: "my-account-public-registration___ro-drmax",
      children: [{
        path: "/contul-meu/inregistrare/pasul1",
        component: _1a7d72fa,
        name: "my-account-public-registration-step1___ro-drmax"
      }, {
        path: "/contul-meu/inregistrare/pasul2",
        component: _1a6143f8,
        name: "my-account-public-registration-step2___ro-drmax"
      }, {
        path: "/contul-meu/inregistrare/pasul3",
        component: _1a4514f6,
        name: "my-account-public-registration-step3___ro-drmax"
      }, {
        path: "/contul-meu/inregistrare/pasul4",
        component: _1a28e5f4,
        name: "my-account-public-registration-step4___ro-drmax"
      }]
    }, {
      path: "set-token",
      component: _2706bb8a,
      name: "my-account-public-set-token___ro-drmax"
    }, {
      path: "/produse-favorite",
      component: _54e00093,
      name: "my-account-public-favorites___ro-drmax"
    }, {
      path: "competition/thankyou",
      component: _3131e5b9,
      name: "my-account-public-competition-thankyou___ro-drmax"
    }, {
      path: "loyalty-card/choose-card-type",
      component: _3facf496,
      name: "my-account-public-loyalty-card-choose-card-type___ro-drmax"
    }, {
      path: "loyalty-card/first-login",
      component: _2a674d28,
      name: "my-account-public-loyalty-card-first-login___ro-drmax"
    }, {
      path: "/contul-meu/dezabonare-confirmare",
      component: _4b609db0,
      name: "my-account-public-unsubscription-confirmation___ro-drmax"
    }, {
      path: "/contul-meu/card-loialitate/inregistrare-card-success",
      component: _fabeeaf6,
      name: "my-account-public-loyalty-card-step5___ro-drmax"
    }, {
      path: "/contul-meu/card-loialitate/pasul1",
      component: _fb2fa6fe,
      name: "my-account-public-loyalty-card-step1___ro-drmax"
    }, {
      path: "/contul-meu/card-loialitate/pasul2",
      component: _fb1377fc,
      name: "my-account-public-loyalty-card-step2___ro-drmax"
    }, {
      path: "/contul-meu/card-loialitate/pasul3-lipsa-date",
      component: _8fe5d966,
      name: "my-account-public-loyalty-card-step3-personal-data___ro-drmax"
    }, {
      path: "/contul-meu/card-loialitate/pasul3-pin",
      component: _1e2c9f6a,
      name: "my-account-public-loyalty-card-step3-pin___ro-drmax"
    }, {
      path: "/contul-meu/card-loialitate/pasul4",
      component: _fadb19f8,
      name: "my-account-public-loyalty-card-step4___ro-drmax"
    }]
  }, {
    path: "/my-account-public-sorp",
    component: _d548c0e8,
    name: "my-account-public-sorp___ro-drmax",
    children: [{
      path: "/contul-meu/inregistrare",
      component: _a977cee8,
      name: "my-account-public-sorp-registration___ro-drmax"
    }, {
      path: "/my-account/final-step-loginfailed",
      component: _06192f6f,
      name: "my-account-public-sorp-final-step-loginfailed___ro-drmax"
    }, {
      path: "/my-account/final-step-not-verified",
      component: _5c262549,
      name: "my-account-public-sorp-final-step-not-verified___ro-drmax"
    }, {
      path: "/my-account/final-step-verified",
      component: _1edb2c0f,
      name: "my-account-public-sorp-final-step-verified___ro-drmax"
    }]
  }, {
    path: "/newsletter-subscriber-confirm",
    component: _8315cbf8,
    name: "newsletter-subscriber-confirm___ro-drmax"
  }, {
    path: "/ofertele-lunii",
    component: _3ca5c49a,
    name: "special-offers___ro-drmax",
    children: []
  }, {
    path: "/pagebuilder-preview",
    component: _18439b52,
    name: "pagebuilder-preview___ro-drmax"
  }, {
    path: "/post-preview",
    component: _30883173,
    name: "post-preview___ro-drmax"
  }, {
    path: "/product-preview",
    component: _27f0e77b,
    name: "product-preview___ro-drmax"
  }, {
    path: "/program-loialitate",
    component: _04d188b4,
    name: "loyalty___ro-drmax"
  }, {
    path: "/reclamatie-noua",
    component: _55a216cc,
    name: "new-return___ro-drmax",
    children: [{
      path: "/reclamatie-noua/1",
      component: _d398863c,
      name: "new-return-step1___ro-drmax"
    }, {
      path: "/reclamatie-noua/2",
      component: _d37c573a,
      name: "new-return-step2___ro-drmax"
    }, {
      path: "/reclamatie-noua/3",
      component: _d3602838,
      name: "new-return-step3___ro-drmax"
    }, {
      path: "/reclamatie-noua/3-logistic",
      component: _69d695d9,
      name: "new-return-step3-logistic___ro-drmax"
    }]
  }, {
    path: "/retururi",
    component: _a3216ef2,
    name: "returns___ro-drmax"
  }, {
    path: "/baby/medicament",
    component: _3d979cd4,
    name: "baby-medicament___ro-drmax"
  }, {
    path: "/checkout/payment-failed",
    component: _6d83b008,
    name: "checkout-payment-failed___ro-drmax"
  }, {
    path: "/checkout/payment-redirect",
    component: _1e8c0b67,
    name: "checkout-payment-redirect___ro-drmax"
  }, {
    path: "/checkout/thankyou",
    component: _1bf54b99,
    name: "checkout-thankyou___ro-drmax"
  }, {
    path: "/contul-meu/confirmare-email",
    component: _11bbfa0a,
    name: "email-verification___ro-drmax"
  }, {
    path: "/contul-meu/reseteaza-parola",
    component: _0d66fd2b,
    name: "reset-password___ro-drmax"
  }, {
    path: "/intreaba-farmacistul/call-center",
    component: _24028d05,
    name: "askPharmacist-callCenter___ro-drmax"
  }, {
    path: "/intreaba-farmacistul/farmacii",
    component: _510c7e43,
    name: "askPharmacist-pharmacies___ro-drmax"
  }, {
    path: "/intreaba-farmacistul/formular-cu-intrebari",
    component: _68ec607c,
    name: "askPharmacist-questionForm___ro-drmax"
  }, {
    path: "/intreaba-farmacistul/lista-cu-intrebari",
    component: _c634f154,
    name: "askPharmacist-questionList___ro-drmax"
  }, {
    path: "/pharmacies/dermocenters/our-dermocenters",
    component: _3ef9471f,
    name: "pharmacies-dermocenters-our-dermocenters___ro-drmax"
  }, {
    path: "/pharmacies/dermocenters/social-networks",
    component: _74224362,
    name: "pharmacies-dermocenters-social-networks___ro-drmax"
  }, {
    path: "/",
    component: _13eeb22d,
    name: "index___ro-drmax",
    children: []
  }, {
    path: "/contul-meu/confirmare/:token",
    component: _9bbc16d0,
    name: "confirmation-token___ro-drmax"
  }, {
    path: "/contul-meu/parola-noua/:hash",
    component: _59f42558,
    name: "new-password-hash___ro-drmax"
  }, {
    path: "/email/verificare/:token",
    component: _0991542e,
    name: "confirmation-email-token___ro-drmax"
  }, {
    path: "/pharmacies/dermocenters/:dermocenter",
    component: _42d40a9f,
    name: "pharmacies-dermocenters-dermocenter___ro-drmax"
  }, {
    path: "/autori/:author",
    component: _3f61ff56,
    name: "authors-author___ro-drmax"
  }, {
    path: "/checkout/:step",
    component: _254a01b7,
    name: "checkout-step___ro-drmax"
  }, {
    path: "/etichete/:tag",
    component: _5092b942,
    name: "tags-tag___ro-drmax"
  }, {
    path: "/farmacii/:pharmacy",
    component: _18407904,
    name: "pharmacies-pharmacy___ro-drmax"
  }, {
    path: "/intreaba-farmacistul/:question",
    component: _229fd283,
    name: "askPharmacist-question___ro-drmax"
  }, {
    path: "/repay/:hash?",
    component: _040cbcae,
    name: "repay-hash___ro-drmax"
  }, {
    path: "/:prefix?/promo",
    component: _7fe3d023,
    name: "promotion___ro-drmax"
  }, {
    path: "/:prefix?/search",
    component: _be9fffc4,
    name: "search___ro-drmax"
  }, {
    path: "/*",
    component: _242aae65,
    name: "all___ro-drmax"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
