function createSuklWidgetConfig() {
  var codes = [];
  var medicalProdIds = [];
  var vetProdIds = [];
  var certificates = [{
    image: {
      url: "/images/logo-ms.png",
      alt: "Logo Ms",
      width: 120,
      height: 100
    },
    link: "https://ms.ro/ro/informatii-de-interes-public/pacienti/drepturi-pacienti/"
  }, {
    image: {
      url: "/images/pci_logo.png",
      alt: "Logo PCI",
      width: 120,
      height: 100
    }
  }];
  return {
    suklWidget: {
      codes: codes,
      medicalProdIds: medicalProdIds,
      vetProdIds: vetProdIds,
      certificates: certificates
    }
  };
}
export default defineAppConfig(createSuklWidgetConfig());